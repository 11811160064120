/* eslint-disable array-callback-return */
/* eslint-disable default-case */
import produce from "immer";
import {
  SET_CAMPAIGNS,
  SHOW_MESSAGE,
  SET_FOLDERS,
  ADD_FOLDER,
  ADD_CAMPAIGN,
  CHANGE_FOLDER,
  HANDLE_DELETE_FOLDER,
  HANDLE_DELETE_CAMPAIGN,
  CHANGE_CAMPAIGN,
  SET_SINGLE_CAMPAIGN,
  SET_DISCOUNT,
  SET_LOADING,
  SET_AVAILABLE_CAMPAIGNS,
  SET_NEXT_ACTION_BY_CAMPAIGN,
  REMOVE_NEXT_ACTION,
  ADD_NEXT_ACTION,
  PUT_NEXT_ACTION,
  SET_PUBLISHED_CAMPAIGNS,
  PUT_REACTIVATION_NEXT_ACTION,
  ADD_REACTIVATION_NEXT_ACTION,
  REMOVE_REACTIVATION_NEXT_ACTION,
  SET_REACTIVATION_NEXT_ACTION_BY_CAMPAIGN,
  SET_CONTACTS_ON_CAMPAIGN,
  UPDATE_CONTACT_STATUS_CAMPAIGN,
  SET_TIME_CONFIG_TO_CAMPAIGN,
} from "../constants/Campaign";

export const initialState = {
  campaigns: [],
  folders: [],
  message: "",
  campaign: {},
  discounts: [],
  loading: true,
  availableCampaigns: [],
  nextReviewActions: [],
  publishedCampaigns: [],
  nextReactivationAction: [],
  contactsOnCampaign: [],
};

const campaignReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    const { type, payload } = action;
    switch (type) {
      case SET_CAMPAIGNS:
        draft.campaigns = payload;
        break;

      case SHOW_MESSAGE:
        draft.message = payload;
        break;

      case SET_FOLDERS:
        draft.folders = payload;
        break;

      case ADD_FOLDER:
        draft.folders.push(payload);
        break;

      case ADD_CAMPAIGN:
        draft.campaigns.push(payload);
        break;

      case CHANGE_FOLDER:
        draft.folders.map((f, index) => {
          if (f.id === payload.id) {
            draft.folders[index].name = payload.name;
            draft.campaigns.map((campaign, i) => {
              if (campaign?.folder?.id === f.id) {
                draft.campaigns[i].folder.name = payload.name;
              }
            });
          }
        });
        break;
      case HANDLE_DELETE_FOLDER:
        draft.folders.map((f, index) => {
          if (f.id === payload.id) {
            draft.folders = draft.folders
              .slice(0, index)
              .concat(draft.folders.slice(index + 1, draft.folders.length));
            draft.campaigns.map((campaign, i) => {
              if (campaign?.folder?.id === f.id) {
                draft.campaigns[i].folder = null;
              }
            });
          }
        });
        break;

      case HANDLE_DELETE_CAMPAIGN:
        let indexDelete = draft.campaigns.findIndex(
          (el) => el.id === payload.id
        );
        draft.campaigns = draft.campaigns
          .slice(0, indexDelete)
          .concat(
            draft.campaigns.slice(indexDelete + 1, draft.campaigns.length)
          );

        break;

      case CHANGE_CAMPAIGN:
        let indexChange = draft.campaigns.findIndex(
          (el) => el.id === payload.id
        );
        draft.campaigns[indexChange] = payload.campaign;
        draft.campaign = payload.campaign;
        break;

      case SET_SINGLE_CAMPAIGN:
        draft.campaign = payload;
        break;
      case SET_DISCOUNT:
        draft.discounts = payload;
        break;
      case SET_LOADING:
        draft.loading = payload;
        break;
      case SET_AVAILABLE_CAMPAIGNS:
        draft.availableCampaigns = payload;
        break;
      case SET_NEXT_ACTION_BY_CAMPAIGN:
        draft.nextReviewActions = payload;
        break;
      case REMOVE_NEXT_ACTION:
        let newList = draft.nextReviewActions.filter((el) => el.id !== payload);
        draft.nextReviewActions = newList;
        break;
      case ADD_NEXT_ACTION:
        draft.nextReviewActions.push(payload);
        break;
      case PUT_NEXT_ACTION:
        let indexChangeAction = draft.nextReviewActions.findIndex(
          (el) => el.id === payload.id
        );
        draft.nextReviewActions[indexChangeAction] = payload;
        break;
      case SET_PUBLISHED_CAMPAIGNS:
        draft.publishedCampaigns = payload;
        break;
      case SET_REACTIVATION_NEXT_ACTION_BY_CAMPAIGN:
        draft.nextReactivationAction = payload;
        let next_action_list = payload.map((action) => {
          let new_action = {
            id: action.id,
            answer: action.answer,
            primary_campaign: action.primary_campaign.id,
            next_action_review: action.next_action_review.id,
            tags: action.tags,
          };
          return new_action;
        });

        draft.nextReactivationAction = next_action_list;
        break;
      case REMOVE_REACTIVATION_NEXT_ACTION:
        let newReactivationList = draft.nextReactivationAction.filter(
          (el) => el.id !== payload
        );
        draft.nextReactivationAction = newReactivationList;
        break;
      case ADD_REACTIVATION_NEXT_ACTION:
        draft.nextReactivationAction.push(payload);
        break;
      case PUT_REACTIVATION_NEXT_ACTION:
        let indexReactivationNextAction =
          draft.nextReactivationAction.findIndex((el) => el.id === payload.id);
        draft.nextReactivationAction[indexReactivationNextAction] = payload;
        break;
      case SET_CONTACTS_ON_CAMPAIGN:
        draft.contactsOnCampaign = {
          count: payload.data.count,
          current: payload.data.current,
          next: payload.data.next,
          previous: payload.data.previous,
          total_pages: payload.data.total_pages,
          results: payload.data.results,
          isLoading: false,
        };
        break;
      case UPDATE_CONTACT_STATUS_CAMPAIGN:
        let indexCampaignStatus = draft.contactsOnCampaign.results.findIndex(
          (el) => el.id === payload.id
        );
        draft.contactsOnCampaign.results[indexCampaignStatus] = payload;
        break;
      case SET_TIME_CONFIG_TO_CAMPAIGN:
        draft.campaign.time_config = payload;
        break;
    }
  });

export default campaignReducer;
