import {
  FUNNEL_FILTER_TYPE,
  SELECTED_PIPELINE,
  SELECTED_RANGE,
} from "./FunnelConstant";

export const FIRST_PAGE = 1;
export const TABLE_ACTION_SORT = "sort";
export const PER_PAGE_10 = 10;
export const PER_PAGE_5 = 5;
export const CREATED_AT_SORT = "-created_at";

export const KEYS_TO_KEEP_ON_LOGOUT = [
  SELECTED_RANGE,
  FUNNEL_FILTER_TYPE,
  SELECTED_PIPELINE,
];
