import { stringify } from "querystring";
import ApiService from "./ApiService";
import { format } from "util";
import { formatDate } from "helpers/date";
import { UNIT } from "views/app-views/CalendarPage/Scheduler/components/config/constants";

const ENDPOINTS = {
  CREATE_APPOINTMENT: "appointments/",
  GET_APPOINTMENTS: "appointments/",
  DELETE_APPOINTMENT: "appointment/%s/",
  EDIT_APPOINTMENT: "appointment/%s/",
  GET_CALENDAR: "calendar",
  APPONTMENT_NOTIFICATION: "appointments/%s/notification/",
  GET_SCHEDULER: "scheduler",
  CREATE_SCHEDULER_LINK: "scheduler/link/",
  PUBLIC_SCHEDULER: "scheduler/%s/unavailable/",
  CREATE_APPOINTMENT_SHORTURL: "appointments/shortURL/",
  PUBLIC_SCHEDULER_EMPLOYEES_DATA: "scheduler/%s/employees/",
  PUBLIC_SCHEDULER_AVAILABEL_SLOTS: "scheduler/%s/available/",
  RETRIEVE_PUBLIC_SCHEDULER_ITEM: "catalog-items/%s/",
  CREATE_APPOINTMENT_PUBLIC_SCHEDULER: "public/appointment/create/",
  CREATE_2FA_PUBLIC_SCHEDULER: "public/appointment/create_2fa_code/",
  LUNCH_BREAK: "break/",
  ITEM_LUNCH_BREAK: "break/%s/",
  GET_APPOINTMENTS_BY_CONTACT: "appointments/%s/contact/?date=%s",
  GET_SCHEDULER_HEADER_OPT: "scheduler/header-options/",
  GET_MISSED_APPOINTMENT_NOTIFY: "appointments/%s/missed_appointment/",
  GET_PREVIOUS_AND_NEXT_APPOITMENT:
    "appointments/previous-next-appointment/?contact_id=%s",
  GET_ALL_CONTACTS_APPOINTMENTS:
    "appointments/contact-appointments/?contact_id=%s&page=%s&type=%s",
  LUNCH_BREAK_IN_DATE_RANGE: "break/multiple/",
  CHECK_OR_CREATE_APPOINTMENT: "appointments/check-overlap-or-save/",
  CHECK_OR_UPDATE_APPOINTMENT: "appointment/%s/check-overlap-or-update/ ",
  DOCTORS_APPOINTMENTS: "appointments/doctor-appointments/",
  REMOVE_ALL_BREAKS: "break/multiple/delete/",
  GET_TODAY_APPOINTMENTS: "dashboard/appointments/today/?",
  UPDATE_APPOINTMENT_STATUS: "dashboard/appointments/today/%s/status/",
};

class AppointmentService extends ApiService {
  createAppointment = async (values) => {
    return this.apiClient.post(ENDPOINTS.CREATE_APPOINTMENT, values);
  };

  getAppointments = async (month = null, year = null, page, perPage) => {
    const filter =
      "?page=" +
      page +
      "&perPage=" +
      perPage +
      (month ? "&month=" + month + "&year=" + year : "/");
    return this.apiClient.get(`${ENDPOINTS.GET_APPOINTMENTS}${filter}`);
  };

  fetchAppointments = (payload) => {
    return this.apiClient.get(
      `${ENDPOINTS.GET_APPOINTMENTS}?${stringify(payload)}`
    );
  };

  deleteAppointment = async (data) => {
    return this.apiClient.delete(format(ENDPOINTS.DELETE_APPOINTMENT, data.id));
  };

  editAppointment = async (data) => {
    return this.apiClient.patch(
      format(ENDPOINTS.EDIT_APPOINTMENT, data.id),
      data
    );
  };

  getCalendar = async (payload = null) => {
    let searchParams = new URLSearchParams();
    searchParams.append("month", payload ? payload.month : "");
    searchParams.append("year", payload ? payload.year : "");
    const filter = payload ? "/?" + searchParams.toString() : "/";
    return this.apiClient.get(`${ENDPOINTS.GET_CALENDAR}${filter}`);
  };

  appointmentNotification = (id) => {
    return this.apiClient.get(format(ENDPOINTS.APPONTMENT_NOTIFICATION, id));
  };

  getAllContactAppointments = (contactId, page, order) => {
    return this.apiClient.get(
      format(ENDPOINTS.GET_ALL_CONTACTS_APPOINTMENTS, contactId, page, order)
    );
  };

  getScheduler = async (payload) => {
    const queryStringParams = {
      date: formatDate(payload?.selectedDate, "YYYY-MM-DD"),
      type: payload?.mode,
      employee: payload?.employeeFilter,
    };
    if (payload?.mode == UNIT) {
      queryStringParams["equipment"] = payload?.equipmentFilter;
    }

    return this.apiClient.get(
      `${ENDPOINTS.GET_SCHEDULER}?${stringify(queryStringParams)}`
    );
  };

  createSchedulerLink = async (values) => {
    return this.apiClient.post(ENDPOINTS.CREATE_SCHEDULER_LINK, values);
  };

  getPublickScheduler = async (payload) => {
    return this.apiClient.get(format(ENDPOINTS.PUBLIC_SCHEDULER, payload));
  };

  createAppointmentByShortURL = async (values) => {
    return this.apiClient.post(ENDPOINTS.CREATE_APPOINTMENT_SHORTURL, values);
  };

  getPublicSchedulerEmployeesData = async (payload) => {
    return this.apiClient.get(
      format(ENDPOINTS.PUBLIC_SCHEDULER_EMPLOYEES_DATA, payload)
    );
  };

  getPublicSchedulerAvailableSlots = async (id, payload) => {
    return this.apiClient.get(
      `${format(ENDPOINTS.PUBLIC_SCHEDULER_AVAILABEL_SLOTS, id)}?${stringify(
        payload
      )}`
    );
  };

  retrieveSelectedServiceData = async (payload) => {
    return this.apiClient.get(
      format(ENDPOINTS.RETRIEVE_PUBLIC_SCHEDULER_ITEM, payload)
    );
  };

  createAppointmentByPublicScheduler = async (values) => {
    return this.apiClient.post(
      ENDPOINTS.CREATE_APPOINTMENT_PUBLIC_SCHEDULER,
      values
    );
  };

  create2FAForPublicScheduler = async (values) => {
    return this.apiClient.post(ENDPOINTS.CREATE_2FA_PUBLIC_SCHEDULER, values);
  };

  createLunchBreak = async (values) => {
    return this.apiClient.post(ENDPOINTS.LUNCH_BREAK, values);
  };

  editLunchBreak = async (data) => {
    return this.apiClient.put(
      format(ENDPOINTS.ITEM_LUNCH_BREAK, data.id),
      data
    );
  };

  deleteLunchBreak = async (data) => {
    return this.apiClient.delete(format(ENDPOINTS.ITEM_LUNCH_BREAK, data.id));
  };

  getAllAppointmentsByContact = async (payload) => {
    return this.apiClient.get(
      format(ENDPOINTS.GET_APPOINTMENTS_BY_CONTACT, payload?.id, payload?.date)
    );
  };

  getSchedulerHeaderOptions = async () => {
    return this.apiClient.get(ENDPOINTS.GET_SCHEDULER_HEADER_OPT);
  };

  getNextAndPreviousAppoitment = async (data) => {
    return this.apiClient.get(
      format(ENDPOINTS.GET_PREVIOUS_AND_NEXT_APPOITMENT, data)
    );
  };

  getDoctorsAppointments = async (page, date) => {
    const queryStringParams = {
      page: page,
    };
    if (date) {
      queryStringParams["date"] = date;
    }
    return this.apiClient.get(
      `${ENDPOINTS.DOCTORS_APPOINTMENTS}?${stringify(queryStringParams)}`
    );
  };

  getMissedApoointmentNotify = async (id) => {
    return this.apiClient.get(
      format(ENDPOINTS.GET_MISSED_APPOINTMENT_NOTIFY, id)
    );
  };

  createLunchBreakInDateRange = async (values) => {
    return this.apiClient.post(ENDPOINTS.LUNCH_BREAK_IN_DATE_RANGE, values);
  };

  checkOrCreateAppointment = async (values) => {
    return this.apiClient.post(ENDPOINTS.CHECK_OR_CREATE_APPOINTMENT, values);
  };

  checkOrEditAppointment = async (data) => {
    return this.apiClient.patch(
      format(ENDPOINTS.CHECK_OR_UPDATE_APPOINTMENT, data.id),
      data
    );
  };

  deleteAllLunchBreak = async (data) => {
    return this.apiClient.post(ENDPOINTS.REMOVE_ALL_BREAKS, data);
  };

  getTodayAppointments = async (page, filters = {}) => {
    const queryParams = {
      ...(page && { page: page }),
      ...(filters.employees && { employees: filters.employees }),
      ...(filters.status && { status: filters.status }),
      ...(filters.customer_care_products && {
        customer_care_products: filters.customer_care_products,
      }),
      ...(filters.date && { date: filters.date }),
    };
    return this.apiClient.get(
      `${ENDPOINTS.GET_TODAY_APPOINTMENTS}${stringify(queryParams)}`
    );
  };

  updateAppointmentStatus = async (appointmentId, status) => {
    return this.apiClient.patch(
      format(ENDPOINTS.UPDATE_APPOINTMENT_STATUS, appointmentId),
      { status }
    );
  };
}

export default new AppointmentService();
