export const NUMBER_PER_PAGE = 10;
export const CONTACTS_PER_PAGE = 5;
export const PHONE_NUMBER_PATTERN =
  "[+[0-9]{3}[0-9]{3}[0-9]{5}|([0-9]{3})[0-9]{3}-[0-9]{5}]";
export const SEARCH_MIN_CHARACTERS = 3;
export const SEARCH_DEBOUNCE = 400;
export const ORDER_CONTACT = {
  ORDER: "order",
  DELETE_AND_ADD: "delete and add",
};
export const TAG_NAME_DISPLAY_LIMIT = 20;
export const NAME_DISPLAY_LIMIT = 17;
export const NAME_DISPLAY_LIMIT_CARD = 14;
export const NUMBER_ACTIVITY_PER_PAGE = 20;
export const CAMPAIGN = "campaign";

export const AMOUNT_SPENT_MAX = 999999;
export const AMOUNT_SPENT_MIN = 0;
export const FIELD_TYPE_NUMBER = "number";
export const FIELD_AMOUNT_SPENT = "amount_spent";

export const DEFAULT_ORDER_FIELD = "created_at";
export const DEFAULT_ORDERING = "-created_at";
export const AMOUNT_SPENT_VALUE = "$%s";

export const MEDIUM_FIELD = "medium";
export const CREATED_FIELD = "created_at";
export const LAST_INTERACTON_FIELD = "last_interaction";
export const EMAIL_FIELD = "email";
export const DEFAULT_COUNTRY = "US";

export const MAX_6_ADDRESS = 6;
export const MAX_7_ADDRESS = 7;

export const ERROR_TYPE_DELETE = "delete_address_error";

export const CONTACTS_STYLES = {
  styleTag: "style-tag",
  styleName: "style-name",
  styleStatus: "style-status",
  styleLink: "style-link",
  stlyeTooltipTag: "style-tooltip-tag",
};

export const DEFAULT_FUNNEL_COLOR = "#0000FF";

export const MAX_NUMBER_FIRST_NAME = 30;
export const MAX_NUMBER_LAST_NAME = 150;
export const MAX_CHAR_ABOUT_YOU = 300;

export const FIRST_PAGE = 1;
export const HISTORY_ACTION_POP = "POP";

export const NAME_FIELD = "Name";
export const FIRST_NAME_FIELD = "First Name";
export const CREATED_AT_FIELD = "Created At";
export const NEW_CREATED_FIELD = "Created";

export const MAX_INSURANCE_PROVIDERS = 2;

export const MAX_NUM_OF_CONTACTS_TAGS_BULK = 10;
export const TAGS_BULK_ADD = "ADD";
export const TAGS_BULK_REMOVE = "REMOVE";
export const TAGS_BULK_ACTION_MODES = {
  ADD: TAGS_BULK_ADD,
  REMOVE: TAGS_BULK_REMOVE,
};

export const MODALS_VISIBILITY = {
  DOCUMENT: false,
  INVOICE: false,
  APPOINTMENT: false,
  PHONECALL: false,
  SMS: false,
  DND_CONTACT: false,
  SCHEDULER_LINK: false,
  COPY_SCH_LINK: false,
  TRANSACTIONS: false,
  DISCLAIMER: false,
};

export const CAMPAIGN_MODALS_VISIBILITY = {
  RENAME: false,
  DELETE: false,
  MULTIPLE_DELETE: false,
  COPY: false,
};

export const TAGS = "tags";
export const STATUS = "status";

export const UNSUBSCRIBED = "unsubscribed";
export const DO_NOT_DISTURB = "doNotDisturb";
export const INVALID_DATA = "invalidData";

export const MAX_NUMBER_MERGE_CONTACTS = 2;

export const SELECT = "select";
export const MERGE = "merge";
export const RADIO = "radio";

export const DELETE = "delete";
export const EDIT = "edit";
export const RUNNING = "Running";

export const STATUS_FIELD = "Status";
export const CAMPAIGNS_FIELD = "Campaigns";

export const ACTIONS_FIELDS_MODALS_VISIBILITY = {
  STATUS: false,
  CAMPAIGNS: false,
};

export const REGULAR = "Regular";
export const FOLlOW_UP = "Follow_up";
export const FOLlOW_UP_NAME = "Follow Up";
export const COUNTDOWN = "Countdown";

export const categoryList = [
  {
    id: REGULAR,
    label: REGULAR,
  },
  {
    id: FOLlOW_UP,
    label: FOLlOW_UP_NAME,
  },
  {
    id: COUNTDOWN,
    label: COUNTDOWN,
  },
];
export const PHONE_NUMBER_FIELD = "phone_number";

export const CONTACTS = "Contacts";
export const BULK_ACTION = "Bulk Actions";

export const TABS = {
  CONTACTS: CONTACTS,
  BULK_ACTIONS: BULK_ACTION,
};
