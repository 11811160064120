const translations = [
  { "en-US": "Contacts" },
  { "en-US": "Client Flow" },
  { "en-US": "Marketing" },
  { "en-US": "Campaigns" },
  { "en-US": "Email Builder" },
  { "en-US": "SMS Templates" },
  { "en-US": "Scheduler" },
  { "en-US": "Settings" },
  { "en-US": "Conversations" },
  { "en-US": "Landing Page" },
  { "en-US": "Form Builder" },
  { "en-US": "Chat Widget" },
  { "en-US": "My Practice" },
  { "en-US": "Dashboard" },
  { "en-US": "Practice Information" },
  { "en-US": "My Team" },
  { "en-US": "Website Integration" },
  { "en-US": "Loyalty Program" },
  { "en-US": "Rewards" },
  { "en-US": "Users" },
  { "en-US": "Documents" },
  { "en-US": "Reporting" },
  { "en-US": "Transactions" },
  { "en-US": "Marketing" },
  { "en-US": "Loyalty" },
  { "en-US": "Rooms and Catalog" },
  { "en-US": "Virtual Consult" },
  { "en-US": "Employee Calendar" },
  { "en-US": "Treatments" },
  { "en-US": "Memberships" },
  { "en-US": "Summary" },
];

export default translations;
