export const TABS = {
  UNREAD: "unread",
  RECENTS: "recents",
  ALL: "all",
  SMS: "sms",
  EMAIL: "email",
};

export const MODE_TYPE = {
  UNREAD: "unread",
  RECENTS: "recents",
  ALL: "all",
};

export const TAB_TO_MODE = {
  [TABS.UNREAD]: MODE_TYPE.UNREAD,
  [TABS.RECENTS]: MODE_TYPE.RECENTS,
  [TABS.ALL]: MODE_TYPE.ALL,
};

export const TAB_TO_FILTER = {
  [TABS.UNREAD]: 0,
  [TABS.RECENTS]: 1,
  [TABS.ALL]: "",
};

export const FILTER_TO_TAB = {
  0: TABS.UNREAD,
  1: TABS.RECENTS,
  "": TABS.ALL,
};

export const CONVERSATION_TYPE = {
  COMMUNICATION: "communication",
  CALL: "call",
};

export const CONVERSATION_MODE = {
  EMAIL: "email",
  SMS: "sms",
};

export const NUMBER_PER_PAGE = 20;

export const STATUS = {
  RECEIVED: "received",
};

export const PHONE_CALL_STATUS = {
  READY: "Ready",
  ERROR: "ERROR: ",
  IN_CALL: "In call with ",
  END: "Call ended",
  CALL: "Call",
};

export const FILTER_OPTIONS = {
  UNREAD: 0,
  RECENTS: 1,
};

export const INITIAL_SIZE = {
  MESSAGE_LIST: 13,
  CONTACT_INFORMATION: 6,
};

export const COLLAPSED_SIZE = {
  MESSAGE_LIST: 19,
  CONTACT_INFORMATION: 0,
};

export const TIME_RELOAD = 30000;

export const TABS_SIDEBAR = {
  PROFILE: "profile",
  HISTORY: "history",
  NOTES: "notes",
};

export const MODE_TYPE_SIDEBAR = {
  PROFILE: "profile",
  HISTORY: "history",
  NOTES: "notes",
};

export const TAB_TO_MODE_SIDEBAR = {
  [TABS_SIDEBAR.PROFILE]: MODE_TYPE_SIDEBAR.PROFILE,
  [TABS_SIDEBAR.HISTORY]: MODE_TYPE_SIDEBAR.HISTORY,
  [TABS_SIDEBAR.NOTES]: MODE_TYPE_SIDEBAR.NOTES,
};

export const SMALL_AVATAR_STYLE = {
  width: "45px",
  height: "45px",
  lineHeight: "45px",
};

export const MIDIUM_AVATAR_STYLE = {
  width: "28px",
  height: "28px",
  lineHeight: "28px",
};

export const MESSENGER_UPLOAD_FILE_SIZE = 10 * 1024 * 1024;
export const MESSENGER_UPLOAD_FILE_TYPES =
  "image/jpg, image/png, image/jpeg, application/pdf, application/doc";
export const MESSENGER_NUMBER_OF_FILES = 1;
export const MESSENGER_MSG_MAX_LENGTH = 1000;

export const INSTAGRAM_UPLOAD_FILES_TYPES = "image/png, image/jpeg";

export const SUPPORTED_IMAGE_TYPES = [
  "jpeg",
  "png",
  "jpg",
  "gif",
  "bmp",
  "webp",
];

export const SUPPORTED_FILE_TYPES = ["plain", "pdf", "doc", "docx", "xlsx"];
export const MIME_TYPES = [
  "document",
  "presentation",
  "excel",
  "powerpoint",
  "pdf",
  "msword",
  "sheet",
  ...SUPPORTED_FILE_TYPES,
];
export const ATTACHMENT_TYPE_FILE = "File";
export const ATTACHMENT_TYPE_IMAGE = "Image";
export const ATTACHMENT_TYPE_STORY_MENTION = "story mention";
export const ATTACHMENT_TYPE_VIDEO = "video";
export const ATTACHMENT_TYPE_AUDIO = "voice";
export const UPLOAD_DOCUMENT_TYPES = [
  ".doc",
  ".docx",
  ".xls",
  ".xlsx",
  ".ppt",
  ".pptx",
  ".pdf",
];
export const UPLOAD_PICTURES_TYPES = [".jpg", ".png", ".jpeg"];

export const KEY_TRUST_HUB = "?key=9";

export const MOMBILE_BREAKPOINT = 768;
export const FORM_MESSAGE = "Form Message";
