export const PREVIOUS = "previous";
export const FUTURE = "future";
export const PACKAGES = "packages";
export const UNITS = "Units";
export const COUPON = "Coupon";
export const CHECKLIST = "Checklist";
export const INITIAL_PAGE = 1;
export const PER_PAGE = 10;
export const MAX_CHECKLIST = 10;
export const MAX_UNITS = 1000;
export const MIN_UNITS = 1;
export const CHECKED = "checked";
export const COMPLETED = "completed";
export const ACTIVE = "active";
export const UNCONFIRMED = "unconfirmed";
export const CANCELED = "canceled";
export const NOSHOW = "no-show";
export const CONFIRMED = "confirmed";
export const PACKAGE_COMPLETED = "Package completed";
export const COMPLETED_STATUS = "Completed";
export const ACTIVE_STATUS = "Active";
export const EDIT_ACTION = "edit";
export const DELETE_ACTION = "delete";
export const COMPLETED_COLOR = "#04d182";
export const UNCOMPLETED_COLOR = "black";
export const PERCENT_100 = 100;
export const PACKAGE_TIME_FORMAT = "MMM Do YYYY";
export const ALL_VALUE = "";
export const ACTIVE_VALUE = "active";
export const COMPLETED_VALUE = "completed";
export const DELETED_VALUE = "deleted";
export const ITEMS = "items";
export const APPOINTMENTS_MODALS_VISIBILTY = {
  NOTE: false,
  APP_VIEW: false,
  APP_PACKAGE: false,
  APP_EDIT: false,
  TTRANSACTIONS: false,
};
