import produce from "immer";
import {
  SET_NOTIFICATIONS,
  SET_SINGLE_NOTIFICATION,
} from "../constants/NotificationSettings";

export const initialState = {
  notifications: [],
  allNotificationsFlag: null,
};

const notificationsSettingsReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    const { type, payload } = action;
    switch (type) {
      case SET_NOTIFICATIONS:
        payload.forEach((newNotification) => {
          const index = draft.notifications.findIndex(
            (notification) => notification.id === newNotification.id
          );

          if (index !== -1) {
            draft.notifications[index] = {
              ...draft.notifications[index],
              ...newNotification,
            };
          } else {
            draft.notifications.push(newNotification);
          }
        });
        break;
      case SET_SINGLE_NOTIFICATION:
        const notificationIndex = draft.notifications.findIndex(
          (item) => item.id === payload.id
        );
        draft.notifications[notificationIndex] = payload;
        break;
      default:
        return state;
    }
  });

export default notificationsSettingsReducer;
